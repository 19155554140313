import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: '404',
    meta: {
      title: '绑卡管理平台',
    },
    component: () => import(/* webpackChunkName: "router" */ '@/views/404/Index.vue'),
  },

  {
    path: '/login',
    name: 'login',
    meta: {
      title: '绑卡管理-登录',
    },
    component: () => import(/* webpackChunkName: "router" */ '@/views/login/Index.vue'),
  },

  {
    path: '/index',
    name: 'index',
    meta: {
      title: '绑卡管理平台',
    },
    component: () => import(/* webpackChunkName: "router" */ '@/views/index/Index.vue'),
  },
  {
    path: '/bankCardBind',
    name: 'bankCardBind',
    meta: {
      title: '绑定银行卡',
    },
    component: () => import(/* webpackChunkName: "router" */ '@/views/bank_card_bind/Index.vue'),
  },


  {
    path: '/bindAgree',
    name: 'bindAgree',
    meta: {
      title: '绑定银行卡',
    },
    component: () => import(/* webpackChunkName: "router" */ '@/views/bank_card_bind/agree.vue'),
  },
  {
    path: '/face',
    name: 'face',
    meta: {
      title: '绑定银行卡',
    },
    component: () => import(/* webpackChunkName: "router" */ '@/views/bank_card_bind/face.vue'),
  },


  //
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  to.meta && to.meta.title && (document.title = to.meta.title);
  if (to.meta && to.meta.anonymous) {
    return next()
  }
  next()
});

export default router;
